import type {MediaEdge} from '@shopify/hydrogen/storefront-api-types';
import {ATTR_LOADING_EAGER} from '~/lib/const';
import type {MediaImage} from '@shopify/hydrogen/storefront-api-types';
import {useEffect, useState} from 'react';

/**
 * A client component that defines a media gallery for hosting images, 3D models, and videos of products
 */
export function ProductGallery({
  media,
  classNameName,
}: {
  media: MediaEdge['node'][];
  classNameName?: string;
}) {
  if (!media.length) {
    return null;
  }

  const [imgIndex, setImgIndex] = useState(0);

  return (
    <div className="grid gap-2">
      <div className="md:aspect-[4/3]">
        <img
          className="w-full h-full rounded-lg card-image object-cover transition-opacity"
          src={media[imgIndex]?.image.url}
          alt=""
        />
      </div>
      <div className="grid grid-cols-5 gap-4 pt-3">
        {media.slice(0, 5).map((med, i) => {
          const data = {
            ...med,
            image: {
              // @ts-ignore
              ...med.image,
              altText: med.alt || 'Product image',
            },
          } as MediaImage;
          return (
            <div
              key={i}
              className="md:aspect-[4/3]"
              onClick={() => {
                setImgIndex(i);
              }}
            >
              <img
                className="h-auto max-w-full rounded-sm object-cover w-full h-full cursor-pointer"
                src={data.image?.url}
                alt=""
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
