import type {Customer} from '@shopify/hydrogen/storefront-api-types';
import {Link} from '~/components';
import clsx from 'clsx';
import {useEffect, useId, useMemo} from 'react';
import {useFetcher} from '@remix-run/react';
import {ProductCard} from '~/components/common/ProductCard';
import type {
  Product,
  ProductSortKeys,
  Collection as CollectionType
} from '@shopify/hydrogen/storefront-api-types';

interface FeaturedProductsProps {
  count: number;
  heading: string;
  layout?: 'drawer' | 'page';
  onClose?: () => void;
  query?: string;
  reverse?: boolean;
  sortKey: ProductSortKeys;
}

export function RecommendForYou(
  {
    collection
  }: {
    collection: CollectionType
  }
) {

  return (
    <>
      <div className="flex flex-col w-100 items-center justify-between px-5 md:px-12 mb-8">
        <div className="max-w-screen-xl flex w-full justify-between items-center space-x-24 py-8">
          <span className="font-bold whitespace-nowrap text-3xl">
            OUR BESTSELLERS
          </span>
          <div className="title-border-line"></div>
        </div>
        <div className="max-w-screen-xl">
          <div className="grid gap-5 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 ">
            {collection.products.nodes.map(
                (product: any) => (
                  (
                    <ProductCard
                      product={product as Product}
                      key={product.id}
                      // onClick={onClick}
                      quickAdd
                    />
                  )
                ),
              )}
          </div>
        </div>
      </div>
    </>
  );
}
