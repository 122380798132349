import {
  type EnhancedMenu,
  type EnhancedMenuItem,
  useIsHomePath,
} from '~/lib/utils';
import {
  Drawer,
  useDrawer,
  Text,
  Input,
  IconAccount,
  IconBag,
  IconSearch,
  Heading,
  IconMenu,
  IconCaret,
  Section,
  CountrySelector,
  Cart,
  CartLoading,
  Link,
} from '~/components';
import { useParams, Form, Await, useMatches } from '@remix-run/react';
import { useWindowScroll } from 'react-use';
import { Disclosure } from '@headlessui/react';
import { Suspense, useEffect, useMemo } from 'react';
import { useIsHydrated } from '~/hooks/useIsHydrated';
import { useCartFetchers } from '~/hooks/useCartFetchers';
import type { LayoutData } from '../root';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { Metaobject } from '@shopify/hydrogen/storefront-api-types';
import { Button } from 'flowbite-react';
import facebookIcon from '../../public/facebook.svg'
import aubo from '../../public/aubo.png';

export function Layout({
  children,
  layout,
}: {
  children: React.ReactNode;
  layout: LayoutData;
}) {
  return (
    <>
      <div className="flex flex-col min-h-screen">
        <div className="">
          <a href="#mainContent" className="sr-only">
            Skip to content
          </a>
        </div>
        <Header
          // title={layout?.shop.name ?? 'Steel Power Shelving'}
          title={layout?.shop.name ?? 'Steel Power Shelving'}
          menu={layout?.headerMenu}
          promotion={layout?.promotion?.fields[0]?.value || "LET'S ORDER FOR PICK UP OR DELIVERY"}
        />
        <main role="main" id="mainContent" className="flex-grow">
          {children}
        </main>
      </div>
      <Footer menu={layout?.footerMenu} />
      <MessengerCustomerChat pageId="105319011927456" appId="623281669842948" />
    </>
  );
}

function Header({ title, menu, promotion }: { title: string; menu?: EnhancedMenu; promotion: string }) {
  const isHome = useIsHomePath();

  const {
    isOpen: isCartOpen,
    openDrawer: openCart,
    closeDrawer: closeCart,
  } = useDrawer();

  const {
    isOpen: isMenuOpen,
    openDrawer: openMenu,
    closeDrawer: closeMenu,
  } = useDrawer();

  const addToCartFetchers = useCartFetchers('ADD_TO_CART');

  // toggle cart drawer when adding to cart
  useEffect(() => {
    if (isCartOpen || !addToCartFetchers.length) return;
    openCart();
  }, [addToCartFetchers, isCartOpen, openCart]);
  // hack shopify
  menu?.items.forEach((item) => {
    if (item.to.includes('pages/shelving')) {
      item.to = '/shelving';
    } else if (item.to.includes('pages/cabinet')) {
      item.to = '/cabinet';
    } else if (item.to.includes('pages/instruction')) {
      item.to = '/instruction';
    }
  });

  return (
    <>
      <CartDrawer isOpen={isCartOpen} onClose={closeCart} />
      {menu && (
        <MenuDrawer isOpen={isMenuOpen} onClose={closeMenu} menu={menu} />
      )}
      <DesktopHeader
        isHome={isHome}
        title={title}
        menu={menu}
        openCart={openCart}
        promotion={promotion}
      />
      <MobileHeader
        isHome={isHome}
        title={title}
        openCart={openCart}
        openMenu={openMenu}
        promotion={promotion}
      />
    </>
  );
}

function CartDrawer({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  const [root] = useMatches();

  return (
    <Drawer open={isOpen} onClose={onClose} heading="Cart" openFrom="right">
      <div className="grid">
        <Suspense fallback={<CartLoading />}>
          <Await resolve={root.data?.cart}>
            {(cart) => <Cart layout="drawer" onClose={onClose} cart={cart} />}
          </Await>
        </Suspense>
      </div>
    </Drawer>
  );
}

export function MenuDrawer({
  isOpen,
  onClose,
  menu,
}: {
  isOpen: boolean;
  onClose: () => void;
  menu: EnhancedMenu;
}) {
  return (
    <Drawer open={isOpen} onClose={onClose} openFrom="left" heading="Menu">
      <div className="grid">
        <MenuMobileNav menu={menu} onClose={onClose} />
      </div>
      <img
        alt={'AUBO'}
        src={aubo}
        className="h-24 absolute left-20 bottom-0"
      />
    </Drawer>
  );
}

function MenuMobileNav({
  menu,
  onClose,
}: {
  menu: EnhancedMenu;
  onClose: () => void;
}) {
  return (
    <nav className="grid gap-4 p-6 sm:gap-6 sm:px-12 sm:py-8">
      {/* Top level menu items */}
      {(menu?.items || []).map((item) => (
        <span key={item.id} className="block">
          <Link
            to={item.to}
            target={item.target}
            onClick={onClose}
            className={({ isActive }) =>
              isActive ? 'pb-1 border-b -mb-px' : 'pb-1'
            }
          >
            <Text as="span" size="copy">
              {item.title}
            </Text>
          </Link>
        </span>
      ))}
    </nav>
  );
}

function MobileHeader({
  title,
  isHome,
  openCart,
  openMenu,
  promotion
}: {
  title: string;
  isHome: boolean;
  openCart: () => void;
  openMenu: () => void;
  promotion: string;
}) {
  // useHeaderStyleFix(containerStyle, setContainerStyle, isHome);

  const params = useParams();
  return (
    <div>
      <header
        role="banner"
        className="bg-contrast/80 text-primary flex lg:hidden items-center h-nav sticky backdrop-blur-lg z-40 top-0 justify-between w-full leading-none gap-4 px-4 md:px-8"
        // {`${isHome
        //   ? 'bg-primary/80 dark:bg-contrast/60 text-contrast dark:text-primary shadow-darkHeader'
        //   : 'bg-contrast/80 text-primary'
        //   } flex lg:hidden items-center h-nav sticky backdrop-blur-lg z-40 top-0 justify-between w-full leading-none gap-4 px-4 md:px-8`}
      >
        <div className="flex items-center justify-start w-full gap-4">
          <button
            onClick={openMenu}
            className="relative flex items-center justify-center w-8 h-8"
          >
            <IconMenu />
          </button>
          <Form
            method="get"
            action={params.lang ? `/${params.lang}/search` : '/search'}
            className="items-center gap-2 sm:flex"
          >
            <button
              type="submit"
              className="relative flex items-center justify-center w-8 h-8"
            >
              <IconSearch />
            </button>
            <Input
              className={
                isHome
                  ? 'focus:border-contrast/20 dark:focus:border-primary/20'
                  : 'focus:border-primary/20'
              }
              type="search"
              variant="minisearch"
              placeholder="Search"
              name="q"
            />
          </Form>
        </div>

        <Link
          className="flex items-center self-stretch leading-[3rem] md:leading-[4rem] justify-center flex-grow w-full h-full"
          to="/"
        >
          <img
            alt={title}
            src={aubo}
            className="h-10"
          />
        </Link>

        <div className="flex items-center justify-end w-full gap-4">
          <Link
            to="/account"
            className="relative flex items-center justify-center w-8 h-8"
          >
            <IconAccount />
          </Link>
          <CartCount isHome={isHome} openCart={openCart} />
        </div>
      </header>
      <div className="bg-primary w-full py-4 lg:hidden">
        <div className="h-3  flex justify-center text-contrast items-center px-2">
          <p className="px-0 text-xs">{promotion ? promotion.toUpperCase() : "LET'S ORDER FOR PICK UP OR DELIVERY"}</p>
          {isHome && (
            <button className="bg-red-700 hover:bg-red-800 text-white py-2 px-2 ml-1 rounded-full text-xs">
              <Link to="/products">Shop Now!</Link>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

function DesktopHeader({
  isHome,
  menu,
  openCart,
  title,
  promotion
}: {
  isHome: boolean;
  openCart: () => void;
  menu?: EnhancedMenu;
  title: string;
  promotion: string;
}) {
  const params = useParams();
  const { y } = useWindowScroll();
  return (
    <>
      <header
        role="banner"
        className={`bg-contrast/80 text-primary hidden lg:flex items-center flex-col sticky duration-300 backdrop-blur-lg z-40 top-0 justify-between w-full leading-none pt-8`}
      >
        <div className="px-12 max-w-screen-xl w-full flex items-center justify-between">
          <div className="flex gap-12 items-center justify-start">
            <div className="shrink-0">
              <Link className="font-bold" to="/" prefetch="intent">
                <img
                  alt={title}
                  src={aubo}
                  className="h-24"
                />
              </Link>
            </div>

            <nav className="flex gap-8 font-medium">
              {/* Top level menu items */}
              {(menu?.items || []).map((item) => (
                <Link
                  key={item.id}
                  to={item.to}
                  target={item.target}
                  prefetch="intent"
                  className={({ isActive }) =>
                    isActive ? 'pb-1 border-b -mb-px whitespace-nowrap' : 'pb-1 whitespace-nowrap'
                  }
                >
                  {item.title}
                </Link>
              ))}
            </nav>
          </div>
          <div className="flex items-center gap-1">
            <Form
              method="get"
              action={params.lang ? `/${params.lang}/search` : '/search'}
              className="flex items-center gap-2"
            >
              <Input
                className={
                  isHome
                    ? 'focus:border-contrast/20 dark:focus:border-primary/20'
                    : 'focus:border-primary/20'
                }
                type="search"
                variant="minisearch"
                placeholder="Search"
                name="q"
              />
              <button
                type="submit"
                className="relative flex items-center justify-center w-8 h-8 focus:ring-primary/5"
              >
                <IconSearch />
              </button>
            </Form>
            <Link
              to="/account"
              className="relative flex items-center justify-center w-8 h-8 focus:ring-primary/5"
            >
              <IconAccount />
            </Link>
            <CartCount isHome={isHome} openCart={openCart} />
          </div>
        </div>
        <div className="bg-primary w-full">
          <div className="h-16  flex justify-center text-contrast items-center font-bold">
            <p className="px-3">{promotion ? promotion.toUpperCase() : "LET'S ORDER FOR PICK UP OR DELIVERY"}</p>
            {isHome && (
              <button className="bg-red-700 hover:bg-red-800 text-white py-3 px-8 rounded-full text-sm">
                <Link to="/products">Shop Now!</Link>
              </button>
            )}
          </div>
        </div>
      </header>
    </>
  );
}

function CartCount({
  isHome,
  openCart,
}: {
  isHome: boolean;
  openCart: () => void;
}) {
  const [root] = useMatches();

  return (
    <Suspense fallback={<Badge count={0} dark={isHome} openCart={openCart} />}>
      <Await resolve={root.data?.cart}>
        {(cart) => (
          <Badge
            dark={isHome}
            openCart={openCart}
            count={cart?.totalQuantity || 0}
          />
        )}
      </Await>
    </Suspense>
  );
}

function Badge({
  openCart,
  dark,
  count,
}: {
  count: number;
  dark: boolean;
  openCart: () => void;
}) {
  const isHydrated = useIsHydrated();

  const BadgeCounter = useMemo(
    () => (
      <>
        <IconBag />
        <div
          className={`${dark
            ? 'text-primary bg-contrast dark:text-contrast dark:bg-primary'
            : 'text-contrast bg-primary'
            } absolute bottom-1 right-1 text-[0.625rem] font-medium subpixel-antialiased h-3 min-w-[0.75rem] flex items-center justify-center leading-none text-center rounded-full w-auto px-[0.125rem] pb-px`}
        >
          <span>{count || 0}</span>
        </div>
      </>
    ),
    [count, dark],
  );

  return isHydrated ? (
    <button
      onClick={openCart}
      className="relative flex items-center justify-center w-8 h-8 focus:ring-primary/5"
    >
      {BadgeCounter}
    </button>
  ) : (
    <Link
      to="/cart"
      className="relative flex items-center justify-center w-8 h-8 focus:ring-primary/5"
    >
      {BadgeCounter}
    </Link>
  );
}

function Footer({ menu }: { menu?: EnhancedMenu }) {
  const isHome = useIsHomePath();
  const itemsCount = menu
    ? menu?.items?.length + 1 > 4
      ? 4
      : menu?.items?.length + 1
    : [];

  return (
    <div
      className='flex flex-col w-100 items-center justify-between px-5 md:px-12 bg-primary dark:bg-contrast dark:text-primary text-contrast'
    >
      <div className='max-w-screen-xl flex w-full justify-between items-center space-x-24'>
        <Section
          // divider={isHome ? 'none' : 'top'}
          as="footer"
          role="contentinfo"
          className={`grid min-h-[25rem] items-start grid-flow-row w-full gap-6 py-8 px-2 md:px-8 lg:px-12 md:gap-8 lg:gap-12 grid-cols-1 md:grid-cols-2 lg:grid-cols-4
        bg-primary dark:bg-contrast dark:text-primary text-contrast overflow-hidden`}
        >
          <div>
            <Heading className="flex justify-between mb-3" size="lead" as="h2">
              {"get in touch".toUpperCase()}
            </Heading>
            <div className='text-contrast/70'>
              <p>Steel Power Shelving PTY LTD</p>
              <p>ABN : 25 655 838 734</p>
              <p> 07 3807 2316</p>
              <p>0466 787 593 Yatala</p>
              <p>
                0460 000 927 sunshine coast
              </p>
              <p>
                Email : 
              </p>
              <p className='whitespace-nowrap'>
              <a href="mailto:steelpowerau@gmail.com">admin@steelpowergroup.com.au</a>
              </p>
              <p>BSB : 062692</p>
              <p>Account number : 48314603</p>
              <p>PAY ID : 25 655 838 734</p>
              <div className='mt-2'>
                <a href="https://www.facebook.com/SteelPowerShelving" target="_blank"><img className='hover:scale-75 scale-50 cursor-pointer w-16 h-16' src={facebookIcon}></img></a>
              </div>
            </div>
          </div>
          <div>
            <Heading className="flex justify-between mb-3" size="lead" as="h2">
              {"warehouse".toUpperCase()}
            </Heading>
            <div className='text-contrast/70'>
              <p>2/12 Nyholt Drive Yatala QLD 4207</p>
              <p>22 Hancock Way Baringa QLD 4551</p>
            </div>
          </div>
          <FooterMenu menu={menu} />
          {/* <CountrySelector /> */}
          <div
            className={`self-end pt-8 opacity-50 md:col-span-2 lg:col-span-${itemsCount}`}
          >
            &copy; {new Date().getFullYear()} Steel Power Shelving Pty Ltd All Rights Reserved.
          </div>
        </Section>
      </div>
    </div>
  );
}

const FooterLink = ({ item }: { item: EnhancedMenuItem }) => {
  if (item.to.startsWith('http')) {
    return (
      <a href={item.to} target={item.target} rel="noopener noreferrer">
        {item.title}
      </a>
    );
  }

  return (
    <Link to={item.to} target={item.target} prefetch="intent">
      {item.title}
    </Link>
  );
};

function FooterMenu({ menu }: { menu?: EnhancedMenu }) {
  const styles = {
    // section: 'grid gap-4',
    section: '',
    // nav: '',
    nav: 'grid gap-2 pb-6 text-contrast/70',
  };

  return (
    <>
      {(menu?.items || []).map((item: EnhancedMenuItem) => (
        <section key={item.id} className={styles.section}>
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="text-left md:cursor-default">
                  <Heading className="flex justify-between mb-3" size="lead" as="h2">
                    {item.title?.toUpperCase()}
                    {item?.items?.length > 0 && (
                      <span className="md:hidden">
                        <IconCaret direction={open ? 'up' : 'down'} />
                      </span>
                    )}
                  </Heading>
                </Disclosure.Button>
                {item?.items?.length > 0 ? (
                  <div
                    className={`${open ? `max-h-48 h-fit` : `max-h-0 md:max-h-fit`
                      } overflow-hidden transition-all duration-300`}
                  >
                    <Suspense data-comment="This suspense fixes a hydration bug in Disclosure.Panel with static prop">
                      <Disclosure.Panel static>
                        <nav className={styles.nav}>
                          {item.items.map((subItem) => (
                            <FooterLink key={subItem.id} item={subItem} />
                          ))}
                        </nav>
                      </Disclosure.Panel>
                    </Suspense>
                  </div>
                ) : null}
              </>
            )}
          </Disclosure>
        </section>
      ))}
    </>
  );
}
