import type { Customer } from '@shopify/hydrogen/storefront-api-types';
import { Link } from '~/components';
import { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import type { SerializeFrom } from '@shopify/remix-oxygen';
import type { MediaImage, Article } from '@shopify/hydrogen/storefront-api-types';
import { flattenConnection, Image } from '@shopify/hydrogen';

export interface BlogSlidesProps {
    data: any;
}

const BLOG_HANDLE = 'News';

export function BlogSlides({ data }: SerializeFrom<BlogSlidesProps>) {
    if (!data?.articles?.edges) {
        return null
    }
    const articles = data.articles.edges.map((edge:any) => edge.node)
    const [offset, setOffset] = useState(0);
    const [imgsOffset, setImgsOffset] = useState(0);
    const [scrollBarWidth, setScrollBarWidth] = useState('0');
    const [isMouseDown, setMouseDown] = useState(false);
    let containerOutEle: HTMLElement;
    let containerEle: HTMLElement;
    let scrollBarInnerEle: HTMLElement;
    let scrollBarInnerWidth = 0;
    let MaxScrollBarOffset = 0;
    let minImagesBarOffset = 0;
    if (typeof window !== 'undefined') {
        containerOutEle = document.querySelector<HTMLElement>(
            '.slides-bar-container',
        ) as HTMLElement;
        containerEle = document.querySelector<HTMLElement>(
            '.slides-bar-content',
        ) as HTMLElement;
        scrollBarInnerEle = document.querySelector<HTMLElement>(
            '.slides-bar-scroll-inner',
        ) as HTMLElement;
        MaxScrollBarOffset =
            (containerOutEle?.offsetWidth - scrollBarInnerEle?.offsetWidth) /
            scrollBarInnerEle?.offsetWidth;
        minImagesBarOffset =
            -(containerEle?.offsetWidth - containerOutEle?.offsetWidth) /
            containerEle?.offsetWidth;
    }

    const moveToRight = () => {
        containerOutEle.scrollLeft = containerOutEle.scrollWidth;
    };

    const moveToLeft = () => {
        containerOutEle.scrollLeft = 0;
    };
    useEffect(() => {
        if (containerOutEle && containerEle != null && scrollBarInnerEle) {
            scrollBarInnerWidth =
                containerOutEle.offsetWidth / containerEle.offsetWidth;
            setScrollBarWidth(`${scrollBarInnerWidth * 100}%`);
        }

        const handleScroll = () => {
            setOffset(containerOutEle.scrollLeft / containerOutEle.offsetWidth);
        };
        containerOutEle?.addEventListener('scroll', handleScroll);

        const handleMouseUp = () => {
            setMouseDown(false);
        };

        const handleMouseMove = (e: MouseEvent) => {
        };

        const handleMouseDown = () => {
            setMouseDown(true);
        };
        scrollBarInnerEle?.addEventListener('mousedown', handleMouseDown);
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('mousemove', handleMouseMove);
        return () => {
            document.removeEventListener('scroll', handleScroll);
            document.removeEventListener('mousedown', handleMouseDown);
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('mousemove', handleMouseMove);
        };
    });

    return (
        <>
            <div className="flex flex-col w-100 items-center justify-between px-5 md:px-12 pb-12 mb-10">
                <div className="max-w-screen-xl flex w-full justify-between items-center space-x-24 py-8">
                    <span className="text-2xl font-bold whitespace-nowrap lg:text-3xl">
                        Blogs
                    </span>
                    <div className="title-border-line"></div>
                </div>
                <div className="max-w-screen-xl w-full relative slides-bar">
                    <div className="overflow-x-scroll no-scrollbar slides-bar-container scroll-smooth">
                        <div className="inline-flex space-x-4 slides-bar-content mb-6">
                            {articles.map(
                                (article: Article, index: number) => (
                                    <Link to={`/${BLOG_HANDLE}/${article.handle}`} key={index}>
                                        {article.image && (
                                            <div className="w-52 h-36 bg-primary/50 shrink-0 overflow-hidden" key={index}>
                                                <img
                                                    className="w-full object-scale-down"
                                                    key={index}
                                                    src={article?.image?.url}
                                                    alt={article.title}
                                                />
                                            </div>
                                        )}
                                        <h2 className="mt-4 font-2xl font-bold">{article.title}</h2>
                                    </Link>
                                ),
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
