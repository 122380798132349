import type {CartLineInput} from '@shopify/hydrogen/storefront-api-types';
import {useFetcher, useMatches} from '@remix-run/react';
import {Button} from '~/components';
import {CartAction} from '~/lib/type';
import clsx from 'clsx';

export function AddToCartButton({
  children,
  lines,
  className = '',
  variant = 'primary',
  width = 'full',
  analytics,
  ...props
}: {
  children: React.ReactNode;
  lines: CartLineInput[];
  className?: string;
  variant?: 'primary' | 'secondary' | 'inline';
  width?: 'auto' | 'full';
  analytics?: unknown;
  [key: string]: any;
}) {
  const [root] = useMatches();
  const selectedLocale = root?.data?.selectedLocale;
  const fetcher = useFetcher();

  return (
    <div className="">
      <fetcher.Form action="/cart" method="post">
        <input type="hidden" name="cartAction" value={CartAction.ADD_TO_CART} />
        <input
          type="hidden"
          name="countryCode"
          value={selectedLocale.country}
        />
        <input type="hidden" name="lines" value={JSON.stringify(lines)} />
        <input
          type="hidden"
          name="analytics"
          value={JSON.stringify(analytics)}
        />
        <button className="btn-add-to-cart bg-white hover:bg-gray-300 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow whitespace-nowrap z-20">
          {children}
        </button>
      </fetcher.Form>
    </div>
  );
}
