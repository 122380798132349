import type { Customer } from '@shopify/hydrogen/storefront-api-types';
import { Link, Skeleton } from '~/components';
import { Suspense } from 'react';
import {
  useLoaderData,
  Await,
  useSearchParams,
  useLocation,
  useTransition,
} from '@remix-run/react';
import { Spinner, Avatar } from 'flowbite-react';

export function AccesoriesList({
  accesoryProducts,
  handleClick,
}: {
  accesoryProducts: any;
  handleClick: any;
}) {
  return (
    <Suspense fallback={<Spinner aria-label="loading accessory" />}>
      <Await
        errorElement="There was a problem loading related products"
        resolve={accesoryProducts}
      >
        {(products) => (
          <>
            {products.length ? (
              <>
                <div className="my-3 mx-2">
                  <div className="font-bold text-xl">Add Accessories</div>
                  {products.map((product: any, index: number) => (
                    <div key={index} className="flex py-1 items-center">
                      <input
                        type="checkbox"
                        name="defaultAddress"
                        id="defaultAddress"
                        defaultChecked={product?.isChecked}
                        className="border-gray-500 rounded-sm cursor-pointer border-1"
                        onClick={() => {
                          handleClick(product?.product?.variants?.nodes[0]?.id);
                        }}
                      />
                      <div className="px-2">
                        <Avatar
                          img={product.product.media.nodes[0]?.image.url}
                          bordered={true}
                        />
                      </div>
                      <Link to={`/products/${product?.product?.handle}`}>
                        <div className="font-bold"
                        >
                          {`${product?.product?.title}  $${product?.product?.variants?.nodes[0]?.price?.amount}`}
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </>
            ) : null}
          </>
        )}
      </Await>
    </Suspense>
  );
}
