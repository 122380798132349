import type { Customer } from '@shopify/hydrogen/storefront-api-types';
import { Link } from '~/components';
import { Tooltip, Button } from 'flowbite-react';
import type { SerializeFrom } from '@shopify/remix-oxygen';
import type { MediaImage } from '@shopify/hydrogen/storefront-api-types';

export interface ConnectingShelvingProps {
  data: any;
}

export function ConnectingShelving({ title, data, handler, linkHandler }: SerializeFrom<ConnectingShelvingProps>) {
  const connectingShelvingData = data?.fields.find((obj: any) => obj.key === handler)
  if (!connectingShelvingData?.references?.nodes.length) return null
  return (
    <div className='mb-10'>
      <div className='flex flex-col w-100 items-center justify-between px-5 md:px-12'>
        <div className='max-w-screen-xl flex w-full justify-between items-center space-x-24 py-8'>

          <span className='text-2xl font-bold whitespace-nowrap lg:text-3xl'>{title?.toUpperCase()}</span>
          <div className='md:title-border-line'>
          </div>
        </div>

        <div className='max-w-screen-xl  columns-2 md:columns-3 lg:columns-4 gap-2'>
          <div className='bg-primary text-contrast rounded-lg relative h-48 mb-4'>
            <div className='pl-5 py-7'>
              <Link to={`/collections/${linkHandler}`}>
                <span className='font-bold text-xl hover:underline underline-offset-8 decoration-double'>{`Shop ${title}`}</span>
              </Link>
            </div>

            <div className="p-5 absolute bottom-0 left-0 sm:p-10">
              <Link to={`/collections/${linkHandler}`}>
                <button className="w-8 h-8 rounded-full bg-contrast hover:bg-contrast/80 text-primary text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6 m-auto"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3.75 12a.75.75 0 01.75-.75h13.19l-5.47-5.47a.75.75 0 011.06-1.06l6.75 6.75a.75.75 0 010 1.06l-6.75 6.75a.75.75 0 11-1.06-1.06l5.47-5.47H4.5a.75.75 0 01-.75-.75z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </Link>
              <div
                data-popover
                id="popover-default"
                role="tooltip"
                className="absolute z-10 invisible inline-block w-64 text-sm font-light text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800"
              >
                <div data-popper-arrow></div>
              </div>
            </div>
          </div>

          {connectingShelvingData?.references?.nodes.map(
            (ref: MediaImage, index: number) => {
              return (
                <div className='rounded-lg overflow-hidden relative splash-card mb-4' key={`connect-shelving-${index}`}>
                  <img className="object-cover aspect-[4/3]" src={ref?.image?.url} alt={index.toString()} />
                  <div className='splash-button absolute hidden'>
                    <Link to={`/collections/${linkHandler}`}>
                      <Tooltip
                        content="Shop Now"
                        style="light"
                        arrow={false}
                      >
                        <div className=' w-8 h-8 rounded-full bg-contrast/50  flex hover:scale-90'>
                          <div className='w-4 h-4 rounded-full bg-contrast m-auto'>
                          </div>
                        </div>
                      </Tooltip>
                    </Link>
                  </div>

                </div>
              )
            }
          )}
        </div>
      </div>
    </div>
  );
}
