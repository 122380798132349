import clsx from 'clsx';
import {
  flattenConnection,
  Image,
  Money,
  ShopifyAnalyticsProduct,
  useMoney,
} from '@shopify/hydrogen';
import type { SerializeFrom } from '@shopify/remix-oxygen';
import { Text, Link } from '~/components';
import { AddToCartButton } from '~/components/common/AddToCartBtn';
import { isDiscounted, isNewArrival } from '~/lib/utils';
import { getProductPlaceholder } from '~/lib/placeholders';
import type { MoneyV2, Product } from '@shopify/hydrogen/storefront-api-types';

export function ProductCard({
  product,
  label,
  className,
  loading,
  onClick,
  quickAdd,
}: {
  product: SerializeFrom<Product>;
  label?: string;
  className?: string;
  loading?: HTMLImageElement['loading'];
  onClick?: () => void;
  quickAdd?: boolean;
}) {
  let cardLabel;

  const cardProduct: Product = product?.variants
    ? (product as Product)
    : getProductPlaceholder();
  if (!cardProduct?.variants?.nodes?.length) return null;

  const firstVariant = flattenConnection(cardProduct.variants)[0];

  if (!firstVariant) return null;
  const { image, price, compareAtPrice } = firstVariant;

  if (label) {
    cardLabel = label;
  } else if (isDiscounted(price as MoneyV2, compareAtPrice as MoneyV2)) {
    cardLabel = `-${Math.round((compareAtPrice?.amount - price.amount) / compareAtPrice?.amount * 100)}%`;
  } else if (isNewArrival(product.publishedAt)) {
    cardLabel = 'New';
  }

  const productAnalytics: ShopifyAnalyticsProduct = {
    productGid: product.id,
    variantGid: firstVariant.id,
    name: product.title,
    variantName: firstVariant.title,
    brand: product.vendor,
    price: firstVariant.price.amount,
    quantity: 1,
  };

  return (
    <div className={clsx("relative overflow-hidden product-card", className)}>
      <Link
        onClick={onClick}
        to={`/products/${product.handle}`}
        prefetch="intent"
      >
        <div
          className=
          'grid gap-4 bg-white px-2 py-4 relative product-card-content md:px-5'
        >
          <div className="card-image aspect-[4/5] bg-primary/5 bg-white">
            {image && (
              <Image
                className="aspect-[4/5]  object-scale-down fadeIn"
                data={image}
                alt={image.altText || `Picture of ${product.title}`}
                loading={loading}
              />
            )}
            {
              cardLabel ? <Text
                as="label"
                // size="fine"
                className="absolute top-0 right-0 m-3 text-right text-notice text-3xl bg-red-500 font-bold px-3 py-1 text-white"
              >
                {cardLabel}
              </Text> : null
            }

          </div>
          <div className="grid gap-1">
            <div className='product-title'>
              <span className='font-bold'>
              {product.title}
              </span>
            </div>
            <div className="flex gap-4">
              <Text className="flex gap-4">
                <Money withoutTrailingZeros data={price!} className='text-slate-600' />
                {isDiscounted(price as MoneyV2, compareAtPrice as MoneyV2) && (
                  <CompareAtPrice
                    className={'text-red-600 font-size-xs'}
                    data={compareAtPrice as MoneyV2}
                  />
                )}
              </Text>
            </div>
          </div>
        </div>
      </Link>

      <AddToCartButton
        lines={[
          {
            quantity: 1,
            merchandiseId: firstVariant.id,
          },
        ]}
        variant="secondary"
        className="mt-2"
        analytics={{
          products: [productAnalytics],
          totalValue: parseFloat(productAnalytics.price),
        }}
      >
        <Text as="span" className="flex items-center justify-center gap-2 whitespace-nowrap text-xs">
          Add to Cart
        </Text>
      </AddToCartButton>
    </div>
  );
}

function CompareAtPrice({
  data,
  className,
}: {
  data: MoneyV2;
  className?: string;
}) {
  const { currencyNarrowSymbol, withoutTrailingZerosAndCurrency } =
    useMoney(data);

  const styles = clsx('line-through decoration-red-600 decoration-2', className);

  return (
    <span className={styles}>
      {currencyNarrowSymbol}
      {withoutTrailingZerosAndCurrency}
    </span>
  );
}
