import type {Customer} from '@shopify/hydrogen/storefront-api-types';
import {Link} from '~/components';
import {Carousel} from 'flowbite-react';
import {MEDIAIMAGE_FRAGMENT} from '~/data/fragments';
import {defer, type LoaderArgs} from '@shopify/remix-oxygen';
import type {
  MetafieldReferenceConnection,
  MetaobjectField,
} from '@shopify/hydrogen/storefront-api-types';
import {Await, useLoaderData} from '@remix-run/react';
import {json} from '@shopify/remix-oxygen';
import type {SerializeFrom} from '@shopify/remix-oxygen';
import type {MediaImage} from '@shopify/hydrogen/storefront-api-types';

export interface PromotionCarouselProps {
  data: any;
}

export function PromotionCarousel({
  data,
}: SerializeFrom<PromotionCarouselProps>) {
  const promotionCarouselData = data?.fields.find(
    (obj: any) => obj.key === 'slides',
  );
  if (!promotionCarouselData?.references?.nodes.length) return null;
  return (
    <>
      <div className="relative overflow-hidden after:clear-both after:block after:content-[''] flex flex-col w-100 items-center justify-between pb-12">
        <div className="w-full promotion-carousel">
          <Carousel slideInterval={5000}>
            {promotionCarouselData?.references?.nodes.map(
              (ref: MediaImage, index: number) => {
                return (
                  <img
                    className="block object-cover h-full"
                    key={index}
                    src={ref?.image?.url}
                    alt={index.toString()}
                  />
                );
              },
            )}
          </Carousel>
        </div>
      </div>
    </>
  );
}
