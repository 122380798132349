import type {Customer} from '@shopify/hydrogen/storefront-api-types';
import {Link} from '~/components';
import {useEffect, useState, useRef} from 'react';
import clsx from 'clsx';
import type {SerializeFrom} from '@shopify/remix-oxygen';
import type {MediaImage} from '@shopify/hydrogen/storefront-api-types';

export interface BottomSlidesProps {
  data: any;
}

export function BottomSlides({data}: SerializeFrom<BottomSlidesProps>) {
  const bottomSlidesData = data?.fields.find(
    (obj: any) => obj.key === 'slides_bottom',
  );
  const [offset, setOffset] = useState(0);
  const [imgsOffset, setImgsOffset] = useState(0);
  const [scrollBarWidth, setScrollBarWidth] = useState('0');
  const [isMouseDown, setMouseDown] = useState(false);
  let containerOutEle: HTMLElement;
  let containerEle: HTMLElement;
  let scrollBarInnerEle: HTMLElement;
  let scrollBarInnerWidth = 0;
  let MaxScrollBarOffset = 0;
  let minImagesBarOffset = 0;
  if (typeof window !== 'undefined') {
    containerOutEle = document.querySelector<HTMLElement>(
      '.slides-bar-container',
    ) as HTMLElement;
    containerEle = document.querySelector<HTMLElement>(
      '.slides-bar-content',
    ) as HTMLElement;
    scrollBarInnerEle = document.querySelector<HTMLElement>(
      '.slides-bar-scroll-inner',
    ) as HTMLElement;
    MaxScrollBarOffset =
      (containerOutEle?.offsetWidth - scrollBarInnerEle?.offsetWidth) /
      scrollBarInnerEle?.offsetWidth;
    minImagesBarOffset =
      -(containerEle?.offsetWidth - containerOutEle?.offsetWidth) /
      containerEle?.offsetWidth;
  }

  const moveToRight = () => {
    containerOutEle.scrollLeft = containerOutEle.scrollWidth;
  };

  const moveToLeft = () => {
    containerOutEle.scrollLeft = 0;
  };
  useEffect(() => {
    if (containerOutEle && containerEle != null && scrollBarInnerEle) {
      scrollBarInnerWidth =
        containerOutEle.offsetWidth / containerEle.offsetWidth;
      setScrollBarWidth(`${scrollBarInnerWidth * 100}%`);
    }

    const handleScroll = () => {
      setOffset(containerOutEle.scrollLeft / containerOutEle.offsetWidth);
    };
    containerOutEle?.addEventListener('scroll', handleScroll);

    const handleMouseUp = () => {
      setMouseDown(false);
    };

    const handleMouseMove = (e: MouseEvent) => {
      // if (isMouseDown) {
      //   // set scrollbar translate range
      //   let scrollBarOffset = 0
      //   if (offset + e.movementX / scrollBarInnerEle.offsetWidth > 0 && offset + e.movementX / scrollBarInnerEle.offsetWidth < MaxScrollBarOffset) {
      //     scrollBarOffset = offset + e.movementX / scrollBarInnerEle.offsetWidth
      //   }
      //   if (offset + e.movementX / scrollBarInnerEle.offsetWidth >= MaxScrollBarOffset) {
      //     scrollBarOffset = MaxScrollBarOffset
      //   }
      //   setOffset(scrollBarOffset)
      //   // set iamges bar translate range
      //   let imagesBarOffset = 0
      //   if (imgsOffset - e.movementX / containerOutEle.offsetWidth <= minImagesBarOffset) {
      //     imagesBarOffset = minImagesBarOffset
      //   }
      //   if (imgsOffset - e.movementX / containerOutEle.offsetWidth < 0 && imgsOffset - e.movementX / containerOutEle.offsetWidth >= minImagesBarOffset) {
      //     imagesBarOffset = imgsOffset - e.movementX / containerOutEle.offsetWidth
      //   }
      //   setImgsOffset(imagesBarOffset)
      //   // setImgsOffset(imgsOffset - e.movementX / containerOutEle.offsetWidth)
      // }
    };

    const handleMouseDown = () => {
      setMouseDown(true);
    };
    scrollBarInnerEle?.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousemove', handleMouseMove);
    };
  });

  return (
    <>
      <div className="flex flex-col w-100 items-center justify-between px-5 md:px-12 pb-12">
        <div className="max-w-screen-xl flex w-full justify-between items-center space-x-24 py-8">
          <span className="text-2xl font-bold whitespace-nowrap lg:text-3xl">
            FIVE-STAR RATING
          </span>
          <div className="title-border-line"></div>
        </div>
        <div className="max-w-screen-xl w-full relative slides-bar">
          <div className="overflow-x-scroll no-scrollbar slides-bar-container scroll-smooth">
            <div className="inline-flex space-x-4 slides-bar-content mb-6">
              {bottomSlidesData?.references?.nodes.map(
                (item: MediaImage, index: number) => (
                  <Link to={'./'} key={index}>
                    {/* <div className='w-52 h-72 bg-primary/50 shrink-0' style={{
                      backgroundImage: `url(${item?.image?.url})`
                    }}></div> */}
                    <div className="w-52 h-50 bg-primary/50 shrink-0">
                      <img
                        className="w-full"
                        key={index}
                        src={item?.image?.url}
                        alt={index.toString()}
                      />
                    </div>
                  </Link>
                ),
              )}
            </div>
          </div>
          <div
            className={clsx([
              'w-full h-8 flex items-center slides-bar-scroll',
              isMouseDown ? 'cursor-grabbing' : 'cursor-pointer',
            ])}
          >
            <span className="slides-bar-scroll-out bg-slate-300 w-full h-0.5 rounded">
              <span
                className={clsx([
                  'slides-bar-scroll-inner h-0.5 rounded bg-primary block ',
                  isMouseDown ? 'cursor-grabbing' : 'cursor-grab',
                ])}
                style={{width: scrollBarWidth, translate: `${offset * 100}%`}}
              ></span>
            </span>
          </div>
          <div className="slide-buttons">
            <div
              className="absolute slide-button-right"
              style={{top: '38%', right: '-1.2rem'}}
              onClick={moveToRight}
            >
              <button className="w-11 h-11 rounded-full bg-primary/80 hover:bg-primary/60 text-primary text-center pl-2 opacity-20">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 text-contrast"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                  />
                </svg>
              </button>
            </div>
            <div
              className="absolute slide-button-left"
              style={{top: '38%', left: '-1.2rem'}}
            >
              <button
                className="w-11 h-11 rounded-full bg-primary/80 hover:bg-primary/60 text-primary text-center pl-2 opacity-20"
                onClick={() => {
                  moveToLeft();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 text-contrast"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 19.5L8.25 12l7.5-7.5"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
